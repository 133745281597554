









import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export enum Variation {
  Concordia = 0,
  Windsor = 1,
}

export default defineComponent({
  name: 'LabKinetics',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    variation: {
      type: Number as PropType<Variation>,
      default: Variation.Concordia,
    },
  },
});
