<template>
  <lab-task><lab-kinetics :variation="variation" /></lab-task>
</template>

<script>
import Vue from 'vue';
import LabKinetics, {Variation} from '../simulations/LabKinetics';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import LabTask from './labs/LabTask';
export default Vue.extend({
  name: 'WindsorKinetics',
  components: {LabKinetics, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    variation() {
      return Variation.Windsor;
    },
  },
});
</script>
